


.card1,
.card2,
.card3,
.card4 {
  position: absolute;
  right:10%;
  top:30%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}

.card1 {
  min-width: 10ch;
  translate: transform(-90px, 0px);
  min-height: 10ch;
  max-width: 100ch;
  max-height: 100ch;
}

.card2 {
  width: 25ch;
  height: 25ch;
  background-image: url('../../assets/images/mainlogo.png');
}

.card3 {
  opacity: 0.9;
  width: 15ch;
  height: 15ch;
  background-image: url('../../assets/images/code.svg');
}

.card4 {
  width: 15ch;
  height: 15ch;
  background-image: url('../../assets/images/online-shopping.svg');
}

.MouseContainer {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background:black;
}

@media only screen and (max-width:740px){
    .MouseContainer {
        display:none;
    }
}

